import axios from "axios";

const mmlApiKey = "9396425f-0fe1-4631-adc9-a3f6621fcbcb";

export const getPropertiesFromBbox = async (sw: any, ne: any) => {
  //const url = `https://avoin-paikkatieto.maanmittauslaitos.fi/kiinteisto-avoin/simple-features/v3/collections/PalstanSijaintitiedot/items?filter=S_INTERSECTS(geometry,POINT(565448.001%206992568.973))&filter-crs=http://www.opengis.net/def/crs/EPSG/0/3067&filter-lang=cql2-text`;

  console.log("sw", sw);
  console.log("ne", ne);

  const urlBbox = `https://avoin-paikkatieto.maanmittauslaitos.fi/kiinteisto-avoin/simple-features/v3/collections/KiinteistorajanSijaintitiedot/items?bbox=${sw.E},${sw.N},${ne.E},${ne.N}&bbox-crs=http://www.opengis.net/def/crs/EPSG/0/3067&crs=http://www.opengis.net/def/crs/EPSG/0/3067&api-key=${mmlApiKey}`;

  const properties = await axios.get(urlBbox);
  return properties;
};

export const getPropertyFromLatLon = async (lat: any, lon: any) => {
  const url = `https://avoin-paikkatieto.maanmittauslaitos.fi/kiinteisto-avoin/simple-features/v3/collections/PalstanSijaintitiedot/items?filter=S_INTERSECTS(geometry,POINT(${lon}%20${lat}))&filter-crs=http://www.opengis.net/def/crs/EPSG/0/3067&filter-lang=cql2-text&api-key=${mmlApiKey}`;

  const property = await axios.get(url);
  return property;
};
