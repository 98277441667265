import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Button as MuiButton,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";
import { baseUrl } from "../../config";
import { Delete, Edit, Search } from "@mui/icons-material";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import { Formik } from "formik";
import {
  updateProperty,
  createProperty,
  openPropertyForm,
  fetchProperties,
  setProperty,
  deleteProperty,
} from "../../redux/slices/property";
import road from "../../redux/slices/road";
import useAuth from "../../hooks/useAuth";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Button = styled(MuiButton)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

function PropertyForm(props: any) {
  const dispatch = useAppDispatch();
  const { property } = useAppSelector((state) => state.property);

  const handleSubmit = async (
    // eslint-disable-next-line
    values: any,
    // eslint-disable-next-line
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      if (property && property.id !== undefined) {
        dispatch(updateProperty(property.id, values));
      } else {
        dispatch(createProperty(values));
      }

      resetForm();
      setStatus({ sent: true });
      setSubmitting(false);
      // eslint-disable-next-line
      dispatch(openPropertyForm());
    } catch (error: unknown) {
      setStatus({ sent: false });
      setErrors({ submit: "Error" });
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={
        property
          ? {
              name: property.name,
              code: property.code,
              address: property.address,
            }
          : {
              name: "",
              code: "",
              address: "",
            }
      }
      // validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Card mb={6}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Kiinteistöt
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                <Grid item md={6}>
                  <TextField
                    name="name"
                    label="Nimi"
                    value={values.name}
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    name="code"
                    label="Kiinteistötunnus"
                    value={values.code}
                    error={Boolean(touched.code && errors.code)}
                    fullWidth
                    helperText={touched.code && errors.code}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
                <Grid item md={12}>
                  <TextField
                    name="address"
                    label="Osoite"
                    value={values.address}
                    error={Boolean(touched.address && errors.address)}
                    fullWidth
                    helperText={touched.address && errors.address}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    variant="outlined"
                    my={2}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                mt={3}
                mr={2}
              >
                Tallenna
              </Button>
              <Button
                variant="contained"
                color="error"
                mt={3}
                onClick={props.handleOpen}
              >
                Peruuta
              </Button>
            </form>
          </CardContent>
        </Card>
      )}
    </Formik>
  );
}

function PropertiesList(props: any) {
  return (
    <Card mb={6}>
      <CardContent
        pb={1}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Kiinteistöt
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size={"small"}
          onClick={() => props.handleEditUser(undefined)}
        >
          Lisää
        </Button>
      </CardContent>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nimi</TableCell>
              <TableCell>Kiinteistötunnus</TableCell>
              <TableCell>Osoite</TableCell>
              <TableCell>Toiminnot</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(props.rows || []).map((row: any) => (
              <TableRow key={row.code} hover>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.code}</TableCell>
                <TableCell>{row.address}</TableCell>
                <TableCell>
                  <Search
                    style={{ cursor: "pointer" }}
                    onClick={() => console.log("show")}
                  />{" "}
                  <Edit
                    style={{ cursor: "pointer" }}
                    onClick={() => props.handleEditUser(row)}
                  />{" "}
                  <Delete
                    style={{ cursor: "pointer" }}
                    onClick={() => props.handleDelete(row)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Card>
  );
}

function Properties() {
  const { user } = useAuth();

  const dispatch = useAppDispatch();
  const { properties, open } = useAppSelector((state) => state.property);

  const handleOpen = () => {
    dispatch(openPropertyForm());
  };

  const handleEditProperty = (property: any) => {
    dispatch(setProperty(property));
    dispatch(openPropertyForm());
  };

  const handleDelete = (property: any) => {
    // TODO confirm
    dispatch(deleteProperty(property.id));
  };

  useEffect(() => {
    dispatch(fetchProperties(user?.userRoadsRoad[0].id));
  }, [dispatch, user?.userRoadsRoad]);

  return (
    <React.Fragment>
      <Helmet title="Kiinteistöt" />
      <Typography variant="h3" gutterBottom display="inline">
        Kiinteistöt
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Etusivu
        </Link>
        <Typography>Kiinteistöt</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      {open ? (
        <PropertyForm handleOpen={handleOpen} />
      ) : (
        <PropertiesList
          rows={properties}
          handleEditUser={handleEditProperty}
          handleDelete={handleDelete}
          openForm={open}
        />
      )}
    </React.Fragment>
  );
}

export default Properties;
