import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";

import {
  Button,
  Alert as MuiAlert,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { getRoadInfo, getRoadInfoFromXY } from "../../services/vayvaService";

import {
  TM35ToWsg84,
  WGS84lalToETRSTM35FINxy,
} from "../../utils/coordinateConverter";
import { geometryToWsg84 } from "../../utils/road";
import {
  getPropertiesFromBbox,
  getPropertyFromLatLon,
} from "../../services/maanmittauslaitosService";

import * as L from "leaflet";
import "proj4leaflet";
import "leaflet/dist/leaflet.css";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

function PropertyMappi(props: any) {
  const { lat, lng, roadLine, roadLineType } = props;
  const mapDiv = useRef<any>(null);
  const map = useRef<mapboxgl.Map | any>(null);
  // let [map, setMap] = useState(null);

  // const [zoom, setZoom] = useState(15);
  const [properties, setProperties] = useState<any[]>([]);
  const [sw, setSW] = useState<any>({});
  const [ne, setNE] = useState<any>({});

  // useEffect(() => {
  //   map.current = new mapboxgl.Map({
  //     container: mapDiv.current, // ERROR
  //     style: "mapbox://styles/mapbox/outdoors-v11",
  //     center: [lng, lat],
  //     zoom: 14,
  //   });

  //   map.current.on("click", async (e: any) => {
  //     console.log("click");
  //   });

  //   map.current.on("load", async () => {
  //     map.current.addControl(new mapboxgl.NavigationControl());

  //     // console.log("bbox", map.current.getBounds());

  //     const bbox = map.current.getBounds();

  //     const swTm35Fin = WGS84lalToETRSTM35FINxy({
  //       La: bbox._sw.lat,
  //       Lo: bbox._sw.lng,
  //     });

  //     const neTm35Fin = WGS84lalToETRSTM35FINxy({
  //       La: bbox._ne.lat,
  //       Lo: bbox._ne.lng,
  //     });

  //     setSW({ lat: swTm35Fin.La, lng: swTm35Fin.Lo });
  //     setNE({ lat: neTm35Fin.La, lng: neTm35Fin.Lo });

  //     console.log("swTm35Fin", swTm35Fin);
  //     console.log("neTm35Fin", neTm35Fin);

  //     const mmlProperties = await getPropertiesFromBbox(swTm35Fin, neTm35Fin);

  //     for (let i = 0; i < mmlProperties.data.features.length; i++) {
  //       const converted = convertLineString(mmlProperties.data.features[i]);

  //       mmlProperties.data.features[i].geometry.coordinates = converted;

  //       map.current.addSource("property-" + mmlProperties.data.features[i].id, {
  //         type: "geojson",
  //         data: {
  //           type: "Feature",
  //           properties: {},
  //           geometry: {
  //             type: mmlProperties.data.features[i].geometry.type,
  //             coordinates: mmlProperties.data.features[i].geometry.coordinates,
  //           },
  //         },
  //       });

  //       map.current.addLayer({
  //         id: "property-" + mmlProperties.data.features[i].id,
  //         type: "line",
  //         source: "property-" + mmlProperties.data.features[i].id,
  //         layout: {
  //           "line-join": "round",
  //           "line-cap": "round",
  //         },
  //         paint: {
  //           "line-color": "red",
  //           "line-width": 2,
  //         },
  //       });
  //     }
  //   });
  // }, [lat, lng, roadLine, roadLineType]);

  return (
    <div>
      <div style={{ height: "600px" }} ref={mapDiv} className="map-container" />
    </div>
  );
}

function PropertyRegister() {
  const { user } = useAuth();

  const [lat, setLat] = useState<number>(60.322768478462756);
  const [lng, setLng] = useState<number>(24.33700472214691);

  var baseUrls: any = {
    Peruskartta:
      "https://avoin-karttakuva.maanmittauslaitos.fi/avoin/wmts/1.0.0/selkokartta/default/ETRS-TM35FIN/{z}/{y}/{x}.png",
    Taustakartta:
      "https://avoin-karttakuva.maanmittauslaitos.fi/avoin/wmts/1.0.0/taustakartta/default/ETRS-TM35FIN/{z}/{y}/{x}.png",
    Ortoilmakuva:
      "https://avoin-karttakuva.maanmittauslaitos.fi/avoin/wmts/1.0.0/ortokuva/default/ETRS-TM35FIN/{z}/{y}/{x}.png",
  };

  var overlayUrls: any = {
    Kiinteistörajat: [
      "https://avoin-karttakuva.maanmittauslaitos.fi/avoin/wmts/1.0.0/kiinteistojaotus/default/ETRS-TM35FIN/{z}/{y}/{x}.png",
      { minZoom: 10 },
    ],
    Kiinteistötunnukset: [
      "https://avoin-karttakuva.maanmittauslaitos.fi/avoin/wmts/1.0.0/kiinteistotunnukset/default/ETRS-TM35FIN/{z}/{y}/{x}.png",
      { minZoom: 10 },
    ],
  };

  var baseMaps: any = {};
  var overlayMaps: any = {};

  function getOptions(key: any) {
    return {
      detectRetina: true,
      attribution: key,
      maxZoom: 16,
    };
  }

  Object.keys(baseUrls).forEach(function (key) {
    baseMaps[key] = L.tileLayer(baseUrls[key], getOptions(key));
  });

  Object.keys(overlayUrls).forEach(function (key) {
    overlayMaps[key] = L.tileLayer(
      overlayUrls[key][0],
      Object.assign({}, getOptions(key), overlayUrls[key][1])
    );
  });

  const crs = new L.Proj.CRS(
    "EPSG:3067",
    "+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs",
    {
      resolutions: [
        8192, 4096, 2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1, 0.5,
        0.25,
      ],
      origin: [-548576, 8388608],
      bounds: L.bounds([-548576, 8388608], [1548576, 6291456]),
    }
  );

  const mapParams: L.MapOptions = {
    center: L.latLng([60.322768478462756, 24.33700472214691]),
    zoom: 11,
    crs: crs,
    layers: [
      baseMaps["Taustakartta"],
      overlayMaps["Kiinteistörajat"],
      overlayMaps["Kiinteistötunnukset"],
    ],
  };

  const mapStyles: React.CSSProperties = {
    overflow: "hidden",
    width: "60vw",
    height: "60vh",
  };

  const addProperty = () => {
    // TODO
  };

  React.useEffect(() => {
    const map = L.map("map", mapParams);
    L.control.layers(baseMaps, overlayMaps).addTo(map);

    map.on("click", (e: any) => {
      var coord = e.latlng;
      var lat = coord.lat;
      var lon = coord.lng;

      async function fetchProperty() {
        // You can await here
        const finLatLon = await WGS84lalToETRSTM35FINxy({ La: lat, Lo: lon });
        console.log("fin", finLatLon);
        const property = await getPropertyFromLatLon(finLatLon.N, finLatLon.E);
        console.log("property", property);

        const wsg84Polygon = geometryToWsg84(property.data.features[0]);

        console.log("wsg84Polygon", wsg84Polygon);

        var myLines = [
          {
            type: property.data.features[0].geometry.type,
            coordinates: property.data.features[0].geometry.coordinates,
          },
        ];

        var myStyle = {
          color: "#ff7800",
          weight: 5,
          opacity: 0.65,
        };

        L.geoJSON(myLines, {
          style: myStyle,
        }).addTo(map);
      }
      fetchProperty();
    });
  }, []);

  return (
    <React.Fragment>
      <div id="map" style={mapStyles} />
    </React.Fragment>
  );
}

export default PropertyRegister;
