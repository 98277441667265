import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import axios from "axios";
import { baseUrl } from "../../../config";
import { fetchLedger } from "../../../redux/slices/accounting";
import useAppSelector from "../../../hooks/useAppSelector";
import useAppDispatch from "../../../hooks/useAppDispatch";
import useAuth from "../../../hooks/useAuth";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function Ledger(props: any) {
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const { ledger } = useAppSelector((state) => state.accounting);

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    dispatch(
      fetchLedger({
        roadId: user?.userRoadsRoad[0].road.id,
        from: "2020-01-01",
        to: "2024-12-31",
      })
    );
  }, [dispatch, user?.userRoadsRoad]);

  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        Pääkirja
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
          Pages
        </Link>
        <Typography>Pääkirja</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Pääkirja
              </Typography>

              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Tilinumero</TableCell>
                    <TableCell>Kuvaus</TableCell>
                    <TableCell>Debit</TableCell>
                    <TableCell>Credit</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(ledger || []).map((row: any) => (
                    <TableRow key={row.accountCode} hover>
                      <TableCell>{row.accountCode}</TableCell>
                      <TableCell>{row.description}</TableCell>
                      <TableCell>{row.debit}</TableCell>
                      <TableCell>{row.credit}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Ledger;
