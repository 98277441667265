import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Button as MuiButton,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { baseUrl } from "../../../config";
import { Delete, Edit, Search } from "@mui/icons-material";
import { Formik } from "formik";
import {
  FiscalYear,
  fetchFiscalYears,
  openFiscalYearsForm,
} from "../../../redux/slices/accounting";
import useAppDispatch from "../../../hooks/useAppDispatch";
import useAppSelector from "../../../hooks/useAppSelector";
import { format } from "date-fns";
import useAuth from "../../../hooks/useAuth";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Button = styled(MuiButton)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

// function UserForm(props: any) {
//   const dispatch = useAppDispatch();
//   const { user } = useAppSelector((state) => state.user);

//   const handleSubmit = async (
//     // eslint-disable-next-line
//     values: any,
//     // eslint-disable-next-line
//     { resetForm, setErrors, setStatus, setSubmitting }: any
//   ) => {
//     try {
//       if (user && user.id !== undefined) {
//         dispatch(updateUser(user.id, values));
//       } else {
//         dispatch(createUser(values));
//       }

//       resetForm();
//       setStatus({ sent: true });
//       setSubmitting(false);
//       // eslint-disable-next-line
//       dispatch(openUserForm());
//     } catch (error: unknown) {
//       setStatus({ sent: false });
//       setErrors({ submit: "Error" });
//       setSubmitting(false);
//     }
//   };

//   return (
//     <Formik
//       initialValues={
//         user
//           ? {
//               firstName: user.firstName,
//               lastName: user.lastName,
//               email: user.email,
//               password: user.password,
//             }
//           : {
//               firstName: "",
//               lastName: "",
//               email: "",
//               password: "",
//             }
//       }
//       // validationSchema={validationSchema}
//       onSubmit={handleSubmit}
//       enableReinitialize
//     >
//       {({
//         errors,
//         handleBlur,
//         handleChange,
//         handleSubmit,
//         isSubmitting,
//         touched,
//         values,
//       }) => (
//         <Card mb={6}>
//           <CardContent>
//             <Typography variant="h6" gutterBottom>
//               Osakkaat
//             </Typography>
//             <form onSubmit={handleSubmit}>
//               <Grid container spacing={6}>
//                 <Grid item md={6}>
//                   <TextField
//                     name="firstName"
//                     label="Etunimi"
//                     value={values.firstName}
//                     error={Boolean(touched.firstName && errors.firstName)}
//                     fullWidth
//                     helperText={touched.firstName && errors.firstName}
//                     onBlur={handleBlur}
//                     onChange={handleChange}
//                     variant="outlined"
//                     my={2}
//                   />
//                 </Grid>
//                 <Grid item md={6}>
//                   <TextField
//                     name="lastName"
//                     label="Sukunimi"
//                     value={values.lastName}
//                     error={Boolean(touched.lastName && errors.lastName)}
//                     fullWidth
//                     helperText={touched.lastName && errors.lastName}
//                     onBlur={handleBlur}
//                     onChange={handleChange}
//                     variant="outlined"
//                     my={2}
//                   />
//                 </Grid>
//               </Grid>
//               <TextField
//                 name="email"
//                 label="Sähköposti"
//                 value={values.email}
//                 error={Boolean(touched.email && errors.email)}
//                 fullWidth
//                 helperText={touched.email && errors.email}
//                 onBlur={handleBlur}
//                 onChange={handleChange}
//                 type="email"
//                 variant="outlined"
//                 my={2}
//               />
//               <Button
//                 type="submit"
//                 variant="contained"
//                 color="primary"
//                 mt={3}
//                 mr={2}
//               >
//                 Tallenna
//               </Button>
//               <Button
//                 variant="contained"
//                 color="error"
//                 mt={3}
//                 onClick={props.handleOpen}
//               >
//                 Peruuta
//               </Button>
//             </form>
//           </CardContent>
//         </Card>
//       )}
//     </Formik>
//   );
// }

function FiscalYearList(props: any) {
  return (
    <Card mb={6}>
      <CardContent
        pb={1}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Tilikaudet
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size={"small"}
          onClick={() => props.handleEditFiscalYear(undefined)}
        >
          Lisää
        </Button>
      </CardContent>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Alku Pvm</TableCell>
              <TableCell>Loppu Pvm</TableCell>
              <TableCell>Toiminnot</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(props.rows || []).map((row: any) => (
              <TableRow key={row.id} hover>
                <TableCell>
                  {format(new Date(row.from), "dd-MM-yyyy")}
                </TableCell>
                <TableCell>{format(new Date(row.to), "dd-MM-yyyy")}</TableCell>
                <TableCell>
                  <Search
                    style={{ cursor: "pointer" }}
                    onClick={() => console.log("show")}
                  />{" "}
                  <Edit
                    style={{ cursor: "pointer" }}
                    onClick={() => props.handleEditUser(row)}
                  />{" "}
                  <Delete
                    style={{ cursor: "pointer" }}
                    onClick={() => props.handleDelete(row)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Card>
  );
}

function FiscalYears() {
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const { fiscalYears, open } = useAppSelector((state) => state.accounting);

  const handleOpen = () => {
    dispatch(openFiscalYearsForm());
  };

  const handleEditFiscalYear = (fiscalYear: FiscalYear | undefined) => {
    //dispatch(setUser(user));
    dispatch(openFiscalYearsForm());
  };

  // const handleDelete = (user: User) => {
  //   // TODO confirm
  //   dispatch(deleteUser(user.id));
  // };

  useEffect(() => {
    dispatch(fetchFiscalYears(user?.userRoadsRoad[0].road.id));
  }, [dispatch, user?.userRoadsRoad]);

  return (
    <React.Fragment>
      <Helmet title="Osakkaat" />
      <Typography variant="h3" gutterBottom display="inline">
        Osakkaat
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Etusivu
        </Link>
        <Typography>Osakkaat</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      {open ? (
        <p>form</p>
      ) : (
        //<UserForm handleOpen={handleOpen} />
        <FiscalYearList
          rows={fiscalYears}
          handleEditFiscalYear={handleEditFiscalYear}
          //handleDelete={handleDelete}
          openForm={open}
        />
      )}
    </React.Fragment>
  );
}

export default FiscalYears;
