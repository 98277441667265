import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  CardContent,
  Grid,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { ArrowUp, ArrowDown } from "react-feather";
import axios from "axios";
import { baseUrl } from "../../../config";
import { format } from "date-fns";
import { fetchJournal } from "../../../redux/slices/accounting";
import useAuth from "../../../hooks/useAuth";
import useAppDispatch from "../../../hooks/useAppDispatch";
import useAppSelector from "../../../hooks/useAppSelector";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function Journal(props: any) {
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const { journal } = useAppSelector((state) => state.accounting);

  const [open, setOpen] = React.useState(false);

  // useEffect(() => {
  //   axios(`${baseUrl}/accounting/reports/journal?from=2020-01-01&to=2024-12-31`)
  //     .then((res) => {
  //       setJournal(res.data);
  //     })
  //     .catch((err) => {
  //       console.log("err", err);
  //     });
  // }, []);

  useEffect(() => {
    dispatch(
      fetchJournal({
        roadId: user?.userRoadsRoad[0].road.id,
        from: "2020-01-01",
        to: "2024-12-31",
      })
    );
  }, [dispatch, user?.userRoadsRoad]);

  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        Päiväkirja
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Etusivu
        </Link>
        <Link component={NavLink} to="/">
          Talous
        </Link>
        <Typography>Päiväkirja</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Päiväkirja
              </Typography>

              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Tilinumero</TableCell>
                    <TableCell>Kuvaus</TableCell>
                    <TableCell>Debit</TableCell>
                    <TableCell>Credit</TableCell>
                    <TableCell>Aikaleima</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(journal || []).map((row: any) => (
                    <TableRow key={row.id} hover>
                      <TableCell>{row.accountCode}</TableCell>
                      <TableCell>{row.description}</TableCell>
                      <TableCell>
                        {new Intl.NumberFormat("fi-FI", {
                          style: "currency",
                          currency: "EUR",
                        }).format(row.debit)}
                      </TableCell>
                      <TableCell>
                        {new Intl.NumberFormat("fi-FI", {
                          style: "currency",
                          currency: "EUR",
                        }).format(row.credit)}
                      </TableCell>
                      <TableCell>
                        {format(new Date(row.date), "dd-MM-yyyy HH:mm")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Journal;
