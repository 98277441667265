import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";

import {
  Button,
  Alert as MuiAlert,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";
import useAuth from "../../hooks/useAuth";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { getRoadInfo, getRoadInfoFromXY } from "../../services/vayvaService";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import {
  TM35ToWsg84,
  WGS84lalToETRSTM35FINxy,
} from "../../utils/coordinateConverter";
import { geometryToWsg84, lineStringConvert } from "../../utils/road";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

function Mappi(props: any) {
  const { lat, lng, roadLine, roadLineType } = props;
  const mapDiv = useRef<any>(null);
  const map = useRef<mapboxgl.Map | any>(null);
  // let [map, setMap] = useState(null);

  const [zoom, setZoom] = useState(9);
  const [childRoads, setChildRoads] = useState<any[]>([]);

  console.log("roadLine", roadLine);

  useEffect(() => {
    const handleRoad = (road: any) => {
      if (road.name && !childRoads.some((c) => c.name === road.name)) {
        addRoad(road);
      } else {
        removeRoad(road);
      }
    };

    const addRoad = (road: any) => {
      setChildRoads(childRoads.concat(road));
    };

    const removeRoad = (road: any) => {
      setChildRoads(childRoads.filter((c) => c.name !== road.name));
    };

    // const attachMap = (
    //   setMap: React.Dispatch<React.SetStateAction<any>>,
    //   mapDiv: React.RefObject<HTMLDivElement>
    // ) => {
    // if (!mapDiv.current) {
    //   return;
    // }
    map.current = new mapboxgl.Map({
      container: mapDiv.current, // ERROR
      // container: mapDiv.current || "", // NO ERROR
      style: "mapbox://styles/mapbox/outdoors-v11",
      center: [lng, lat],
      zoom: 14,
    });

    map.current.on("click", async (e: any) => {
      console.log(e.lngLat.wrap());
      const WGS84Lng = e.lngLat.wrap().lng;
      const WGS84Lat = e.lngLat.wrap().lat;

      const tm35fin = WGS84lalToETRSTM35FINxy({
        La: WGS84Lat,
        Lo: WGS84Lng,
      });

      console.log("tm35fin", tm35fin);

      const childRoadName = await getRoadInfoFromXY(tm35fin.E, tm35fin.N);

      console.log("child", childRoadName);

      const childRoad = await getRoadInfo(
        childRoadName.data.features[0].properties.katunimi,
        childRoadName.data.features[0].properties.kuntanimi
      );

      console.log("chilRoad", childRoad);

      const convertedChildRoad = geometryToWsg84(childRoad.data.features[0]);

      const tmpRoad = {
        name: childRoad.data.features[0].properties.katunimi,
        municipality: childRoad.data.features[0].properties.kuntanimi,
        distanceM: childRoad.data.features[0].properties.viivan_pituus,
        geoLine: convertedChildRoad,
        geoType: childRoad.data.features[0].geometry.type,
      };

      handleRoad(tmpRoad);
    });

    map.current.on("load", () => {
      // setMap(map);
      // map.resize();

      map.current.addControl(new mapboxgl.NavigationControl());

      map.current.addSource("viivalla", {
        type: "geojson",
        data: {
          type: "Feature",
          properties: {},
          geometry: {
            type: "Point",
            coordinates: [24.394471209244063, 60.34760755147607],
          },
        },
      });

      map.current.addLayer({
        id: "viivalla",
        type: "circle",
        source: "viivalla",
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": "red",
          "line-width": 5,
        },
      });

      map.current.addSource("point", {
        type: "geojson",
        data: {
          type: "Feature",
          properties: {},
          geometry: {
            type: "Point",
            coordinates: [24.39537151, 60.34510343],
          },
        },
      });

      map.current.addLayer({
        id: "point",
        type: "circle",
        source: "point",
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": "red",
          "line-width": 5,
        },
      });

      map.current.addSource("route", {
        type: "geojson",
        data: {
          type: "Feature",
          properties: {},
          geometry: {
            type: roadLineType,
            coordinates: roadLine,
          },
        },
      });

      map.current.addLayer({
        id: "route",
        type: "line",
        source: "route",
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": "blue",
          "line-width": 2,
        },
      });

      for (let i = 0; i < childRoads.length; i++) {
        map.current.addSource(childRoads[i].name, {
          type: "geojson",
          data: {
            type: "Feature",
            properties: {},
            geometry: {
              type: childRoads[i].geoType,
              coordinates: childRoads[i].geoLine,
            },
          },
        });

        map.current.addLayer({
          id: childRoads[i].name,
          type: "line",
          source: childRoads[i].name,
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "blue",
            "line-width": 2,
          },
        });
      }
    });

    // setMap(map);
    //};
  }, [lat, lng, roadLine, roadLineType, childRoads]);

  return (
    <div>
      <div style={{ height: "600px" }} ref={mapDiv} className="map-container" />
    </div>
  );
}

function RoadRegister() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [lat, setLat] = useState<number>();
  const [lng, setLng] = useState<number>();
  const [roadLineType, setRoadLineType] = useState<string>();
  const [roadLine, setRoadLine] = useState<any>();

  const [roadName, setRoadName] = useState<string>();
  const [roadMunicipality, setRoadMunicipality] = useState<string>();
  const [showMap, setShowMap] = React.useState(false);

  console.log("user", user);

  if (showMap) {
    return (
      <div style={{ width: "1000px" }}>
        <Mappi
          lat={lat}
          lng={lng}
          roadLine={roadLine}
          roadLineType={roadLineType}
        />
        <div>
          <p>
            Onko seuraava {roadName}, {roadMunicipality} katu merkitty oikein?
          </p>
        </div>
      </div>
    );
  }

  return (
    <Formik
      initialValues={{
        streetName: "",
        municipality: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        streetName: Yup.string().max(255).required("Street name is required"),
        municipality: Yup.string()
          .max(255)
          .required("Municipality is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const road = await getRoadInfo(
            values.streetName,
            values.municipality
          );

          const E = road.data.features[0].properties.x;
          const N = road.data.features[0].properties.y;

          console.log("x", E);
          console.log("y", N);

          const latlng = TM35ToWsg84(N, E);

          console.log("lat", latlng.lat);
          console.log("lng", latlng.long);

          setLat(latlng.lat);
          setLng(latlng.long);
          setRoadName(road.data.features[0].properties.katunimi);
          setRoadMunicipality(road.data.features[0].properties.kuntanimi);

          // const convertedRoad = multiLineStringConvert(
          //   road.data.features[0].geometry.coordinates
          // );

          console.log("road", road);

          const convertedRoad = geometryToWsg84(road.data.features[0]);

          //const hiidensilta = TM35ToWsg84(60.34510343, 24.39537151);

          const tmpPoint = TM35ToWsg84(6690328.098, 352940.532);
          console.log("point", tmpPoint);

          console.log("type", road.data.features[0].geometry.type);
          setRoadLineType(road.data.features[0].geometry.type);
          setRoadLine(convertedRoad);

          console.log("converted road", convertedRoad);

          console.log("latlng", latlng);

          console.log("values", values);

          setShowMap(true);
        } catch (error: any) {
          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            type="text"
            name="streetName"
            label="Kadun nimi"
            value={values.streetName}
            error={Boolean(touched.streetName && errors.streetName)}
            fullWidth
            helperText={touched.streetName && errors.streetName}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            type="text"
            name="municipality"
            label="Kunta"
            value={values.municipality}
            error={Boolean(touched.municipality && errors.municipality)}
            fullWidth
            helperText={touched.municipality && errors.municipality}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Lähetä
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default RoadRegister;
