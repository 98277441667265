import { Dispatch, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../config";

export interface FiscalYear {
  id: number;
  from: Date;
  to: Date;
}

export interface Accounting {
  fiscalYears: FiscalYear;
  incomeStatement: any;
  assets: any;
  liabilities: any;
  ledger: any;
  journal: any;
}

export interface AccountingState {
  fiscalYears: FiscalYear[] | undefined;
  incomeStatement: any;
  assets: any;
  liabilities: any;
  ledger: any;
  journal: any;
  open: boolean;
  status: "idle" | "loading" | "failed";
}

const initialState: AccountingState = {
  fiscalYears: undefined,
  incomeStatement: [],
  assets: [],
  liabilities: [],
  ledger: [],
  journal: [],
  open: false,
  status: "idle",
};

export const fetchFiscalYears = createAsyncThunk(
  "accounting/fetchFiscalYears",
  async (roadId: number) => {
    const response = await axios(
      `${baseUrl}/accounting/fiscal-years?roadId=${roadId}`
    );
    const data = await response.data;
    return data;
  }
);

export const fetchFinancialStatements = createAsyncThunk(
  "accounting/fetchFinancialStatements",
  async (params: any) => {
    const { roadId, from, to } = params;
    const response = await axios(
      `${baseUrl}/accounting/reports/financial-statement?roadId=${roadId}&from=${from}&to=${to}`
    );
    const data = await response.data;
    return data;
  }
);

export const fetchLedger = createAsyncThunk(
  "accounting/fetchLedger",
  async (params: any) => {
    const { roadId, from, to } = params;
    const response = await axios(
      `${baseUrl}/accounting/reports/ledger?roadId=${roadId}&from=${from}&to=${to}`
    );
    const data = await response.data;
    return data;
  }
);

export const fetchJournal = createAsyncThunk(
  "accounting/fetchJournal",
  async (params: any) => {
    const { roadId, from, to } = params;
    const response = await axios(
      `${baseUrl}/accounting/reports/journal?roadId=${roadId}&from=${from}&to=${to}`
    );
    const data = await response.data;
    return data;
  }
);

// from 2020-01-01
// to 2024-12-31

const accountingSlice = createSlice({
  name: "accounting",
  initialState,
  reducers: {
    // setUser(state, action) {
    //   state.user = action.payload;
    // },
    // addUser(state, action) {
    //   state.users.push(action.payload);
    // },
    // updateUser(state, action) {
    //   const userId = state.users.findIndex((u) => u.id === action.payload.id);
    //   state.users[userId] = action.payload;
    // },
    // deleteUser(state, action) {
    //   state.users = state.users.filter((u: any) => u.id !== action.payload);
    // },
    setOpen(state) {
      state.open = !state.open;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFiscalYears.fulfilled, (state, action) => {
      state.fiscalYears = action.payload;
    });
    builder.addCase(fetchFinancialStatements.fulfilled, (state, action) => {
      state.incomeStatement = action.payload.incomeStatement;
      state.assets = action.payload.assets;
      state.liabilities = action.payload.liabilities;
    });
    builder.addCase(fetchLedger.fulfilled, (state, action) => {
      state.ledger = action.payload;
    });
    builder.addCase(fetchJournal.fulfilled, (state, action) => {
      state.journal = action.payload;
    });
  },
});

// export function setUser(user: any) {
//   return async (dispatch: Dispatch) => {
//     dispatch(userSlice.actions.setUser(user));
//   };
// }

// export function createUser(user: any) {
//   return async (dispatch: Dispatch) => {
//     const response = await axios.post(`${baseUrl}/users`, user);
//     dispatch(userSlice.actions.addUser(response.data));
//   };
// }

// export function updateUser(id: number, user: any) {
//   return async (dispatch: Dispatch) => {
//     const response = await axios.put(`${baseUrl}/users/${id}`, user);
//     dispatch(userSlice.actions.updateUser(response.data));
//   };
// }

// export function deleteUser(id: number) {
//   return async (dispatch: Dispatch) => {
//     await axios.delete(`${baseUrl}/users/${id}`);
//     dispatch(userSlice.actions.deleteUser(id));
//   };
// }

export function openFiscalYearsForm() {
  return async (dispatch: Dispatch) => {
    dispatch(accountingSlice.actions.setOpen());
  };
}

export default accountingSlice.reducer;
