import { TM35ToWsg84 } from "./coordinateConverter";

export const geometryToWsg84 = (feature: any) => {
  let wsg84Geometry = [];

  if (feature.geometry.type === "Polygon") {
    wsg84Geometry = multiLineStringConvert(feature.geometry.coordinates);
  } else if (feature.geometry.type === "MultiLineString") {
    wsg84Geometry = multiLineStringConvert(feature.geometry.coordinates);
  } else {
    wsg84Geometry = lineStringConvert(feature.geometry.coordinates);
  }
  return wsg84Geometry;
};

export const multiLineStringConvert = (line: any) => {
  let convertedChildRoad = [];

  for (let i = 0; i < line.length; i++) {
    const tmpChildRoad = [];
    for (let j = 0; j < line[i].length; j++) {
      const item = TM35ToWsg84(line[i][j][1], line[i][j][0]);
      tmpChildRoad.push([item.long, item.lat]);
    }
    convertedChildRoad.push(tmpChildRoad);
  }

  return convertedChildRoad;
};

export const lineStringConvert = (line: any) => {
  let convertedChildRoad = [];

  for (let i = 0; i < line.length; i++) {
    const item = TM35ToWsg84(line[i][1], line[i][0]);
    convertedChildRoad.push([item.long, item.lat]);
  }

  return convertedChildRoad;
};
