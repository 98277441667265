// muunnokset TM35FIN koordinaateista WSG84 koordinaateihin

export function TM35ToWsg84(N: any, E: any) {
  // Vakiot
  const f = 1 / 298.257222101;
  const a = 6378137; // Isoakselin puolikas
  const lambda_nolla = 0.471238898; // Keskimeridiaani (rad), 27 astetta
  const k_nolla = 0.9996; // Mittakaavakerroin
  const E_nolla = 500000; // Itäkoordinaatti

  // Kaavat
  const n = f / (2 - f);
  const A1 = (a / (1 + n)) * (1 + Math.pow(n, 2) / 4 + Math.pow(n, 4) / 64);
  const e_toiseen = 2 * f - Math.pow(f, 2);
  const h1 =
    (1 / 2) * n -
    (2 / 3) * Math.pow(n, 2) +
    (37 / 96) * Math.pow(n, 3) -
    (1 / 360) * Math.pow(n, 4);
  const h2 =
    (1 / 48) * Math.pow(n, 2) +
    (1 / 15) * Math.pow(n, 3) -
    (437 / 1440) * Math.pow(n, 4);
  const h3 = (17 / 480) * Math.pow(n, 3) - (37 / 840) * Math.pow(n, 4);
  const h4 = (4397 / 161280) * Math.pow(n, 4);
  const zeeta = N / (A1 * k_nolla);
  const eeta = (E - E_nolla) / (A1 * k_nolla);
  const zeeta1_pilkku = h1 * Math.sin(2 * zeeta) * Math.cosh(2 * eeta);
  const zeeta2_pilkku = h2 * Math.sin(4 * zeeta) * Math.cosh(4 * eeta);
  const zeeta3_pilkku = h3 * Math.sin(6 * zeeta) * Math.cosh(6 * eeta);
  const zeeta4_pilkku = h4 * Math.sin(8 * zeeta) * Math.cosh(8 * eeta);
  const eeta1_pilkku = h1 * Math.cos(2 * zeeta) * Math.sinh(2 * eeta);
  const eeta2_pilkku = h2 * Math.cos(4 * zeeta) * Math.sinh(4 * eeta);
  const eeta3_pilkku = h3 * Math.cos(6 * zeeta) * Math.sinh(6 * eeta);
  const eeta4_pilkku = h4 * Math.cos(8 * zeeta) * Math.sinh(8 * eeta);
  const zeeta_pilkku =
    zeeta - (zeeta1_pilkku + zeeta2_pilkku + zeeta3_pilkku + zeeta4_pilkku);
  const eeta_pilkku =
    eeta - (eeta1_pilkku + eeta2_pilkku + eeta3_pilkku + eeta4_pilkku);
  const beeta = Math.asin(
    (1 / Math.cosh(eeta_pilkku)) * Math.sin(zeeta_pilkku)
  );
  const l = Math.asin(Math.tanh(eeta_pilkku) / Math.cos(beeta));
  const Q = Math.asinh(Math.tan(beeta));
  let Q_pilkku =
    Q + Math.sqrt(e_toiseen) * Math.atanh(Math.sqrt(e_toiseen) * Math.tanh(Q));

  for (let kierros = 1; kierros < 5; kierros++) {
    Q_pilkku =
      Q +
      Math.sqrt(e_toiseen) *
        Math.atanh(Math.sqrt(e_toiseen) * Math.tanh(Q_pilkku));
  }

  function rad2deg(radians: any) {
    var pi = Math.PI;
    return radians * (180 / pi);
  }

  // Tulos radiaaneina
  let fii = Math.atan(Math.sinh(Q_pilkku));
  let lambda = lambda_nolla + l;

  // Tulos asteina
  fii = rad2deg(fii);
  lambda = rad2deg(lambda);

  const coordinate = { lat: fii, long: lambda };
  if (
    Number.isNaN(Object.values(coordinate)[0]) == true &&
    Number.isNaN(Object.values(coordinate)[1]) == true
  ) {
    const defaultCoordinates = { lat: 0, long: 0 };
    return defaultCoordinates;
  } else {
    return coordinate;
  }
}

const toRadians = (angle: number) => {
  return angle * (Math.PI / 180);
};

const ELLIPSOID: any = {
  WGS84: {
    a: 6378137.0,
    b: 6356752.314245,
    f: 1.0 / 298.257223563,
    k0: 0.9996,
  },
  KKJ: { a: 6378388.0, b: 6356911.946128, f: 1.0 / 297.0, k0: 1.0 },
  GRS80: { a: 6378137.0, b: 6356752.31414, f: 1.0 / 298.257222101, k0: 1.0 },
};

// Init precalculated ellipsoid parameters
for (const key in ELLIPSOID) {
  const a = ELLIPSOID[key]["a"];
  const f = ELLIPSOID[key]["f"];

  const n = f / (2.0 - f);
  ELLIPSOID[key]["n"] = n;
  ELLIPSOID[key]["A1"] =
    (a / (1.0 + n)) * (1.0 + Math.pow(n, 2.0) / 4.0 + Math.pow(n, 4.0) / 64.0);
  ELLIPSOID[key]["e"] = Math.sqrt(2.0 * f - Math.pow(f, 2.0));
  ELLIPSOID[key]["h1"] =
    (1.0 / 2.0) * n -
    (2.0 / 3.0) * Math.pow(n, 2.0) +
    (37.0 / 96.0) * Math.pow(n, 3.0) -
    (1.0 / 360.0) * Math.pow(n, 4.0);
  ELLIPSOID[key]["h2"] =
    (1.0 / 48.0) * Math.pow(n, 2.0) +
    (1.0 / 15.0) * Math.pow(n, 3.0) -
    (437.0 / 1440.0) * Math.pow(n, 4.0);
  ELLIPSOID[key]["h3"] =
    (17.0 / 480.0) * Math.pow(n, 3.0) - (37.0 / 840.0) * Math.pow(n, 4.0);
  ELLIPSOID[key]["h4"] = (4397.0 / 161280.0) * Math.pow(n, 4.0);
  ELLIPSOID[key]["h1p"] =
    (1.0 / 2.0) * n -
    (2.0 / 3.0) * Math.pow(n, 2.0) +
    (5.0 / 16.0) * Math.pow(n, 3.0) +
    (41.0 / 180.0) * Math.pow(n, 4.0);
  ELLIPSOID[key]["h2p"] =
    (13.0 / 48.0) * Math.pow(n, 2.0) -
    (3.0 / 5.0) * Math.pow(n, 3.0) +
    (557.0 / 1440.0) * Math.pow(n, 4.0);
  ELLIPSOID[key]["h3p"] =
    (61.0 / 240.0) * Math.pow(n, 3.0) - (103.0 / 140.0) * Math.pow(n, 4.0);
  ELLIPSOID[key]["h4p"] = (49561.0 / 161280.0) * Math.pow(n, 4.0);
}

export function WGS84lalToETRSTM35FINxy(WGSin: any) {
  const lo0 = 27.0;
  const E0 = 500000.0;

  return lalo_to_xy(WGSin["La"], WGSin["Lo"], lo0, E0, ELLIPSOID["WGS84"]);
}

function lalo_to_xy(la: any, lo: any, lo0: any, E0: any, ellipsoid: any) {
  lo0 = toRadians(lo0);
  la = toRadians(la);
  lo = toRadians(lo);

  const e = ellipsoid["e"];
  const k0 = ellipsoid["k0"];
  const h1p = ellipsoid["h1p"];
  const h2p = ellipsoid["h2p"];
  const h3p = ellipsoid["h3p"];
  const h4p = ellipsoid["h4p"];
  const A1 = ellipsoid["A1"];

  const Q = Math.asinh(Math.tan(la)) - e * Math.atanh(e * Math.sin(la));
  const be = Math.atan(Math.sinh(Q));
  const nnp = Math.atanh(Math.cos(be) * Math.sin(lo - lo0));
  const Ep = Math.asin(Math.sin(be) * Math.cosh(nnp));
  const E1 = h1p * Math.sin(2.0 * Ep) * Math.cosh(2.0 * nnp);
  const E2 = h2p * Math.sin(4.0 * Ep) * Math.cosh(4.0 * nnp);
  const E3 = h3p * Math.sin(6.0 * Ep) * Math.cosh(6.0 * nnp);
  const E4 = h4p * Math.sin(8.0 * Ep) * Math.cosh(8.0 * nnp);
  const nn1 = h1p * Math.cos(2.0 * Ep) * Math.sinh(2.0 * nnp);
  const nn2 = h2p * Math.cos(4.0 * Ep) * Math.sinh(4.0 * nnp);
  const nn3 = h3p * Math.cos(6.0 * Ep) * Math.sinh(6.0 * nnp);
  const nn4 = h4p * Math.cos(8.0 * Ep) * Math.sinh(8.0 * nnp);
  const E = Ep + E1 + E2 + E3 + E4;
  const nn = nnp + nn1 + nn2 + nn3 + nn4;

  const XY: any = {};
  XY["N"] = A1 * E * k0;
  XY["E"] = A1 * nn * k0 + E0;

  return XY;
}
