import axios from "axios";

export const getRoadInfo = async (streetName: string, municipality: string) => {
  const vaylaUrl = `https://avoinapi.vaylapilvi.fi/viitekehysmuunnin/muunna?kuntanimi=${municipality}&katunimi=${streetName}&valihaku=true&palautusarvot=1&palautusarvot=2&palautusarvot=3&palautusarvot=4&palautusarvot=5&palautusarvot=6`;
  const road = await axios.get(vaylaUrl);
  return road;
};

export const getRoadInfoFromXY = async (x: number, y: number) => {
  const vaylaUrl = `https://avoinapi.vaylapilvi.fi/viitekehysmuunnin/muunna?x=${x}&y=${y}`;
  const road = await axios.get(vaylaUrl);
  return road;
};
